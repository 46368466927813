/* .client-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    text-align: center;
    padding: 5px;
    display: none;
} */

.col-3:hover .client-overlay {
    display: block;
}

.col-md-9 {
    padding-top: 0px;
}

.clientImage{
    padding-top: 0;
    width: 250px;
}

.client-heading{
    font-size: 3vh;
    font-weight: 500;
    font-family: Alegreya;
    color: #ccc;
}

.client-title{
    word-wrap: break-word; 
    line-height: 1.1;
    font-size: 7vh;
    font-family: Alegreya,serif;
    font-weight: 700;
}

.client-subtitle{
    color: #9c9c9c;
    font-family: Alegreya,serif;
    font-size: 18px;
    font-weight: 300;
    padding-top: 0;
}

.clientFix{
    padding-top: 0;
    margin-top: 0;
    padding-left: 100px;
}

/* Tablet screens */
@media (max-width: 1024px) {
    .client-heading {
      font-size: 2vh; /* Slightly smaller than desktop */
    }
  
    .client-title {
      font-size: 2.8vh; /* Slightly smaller than desktop */
    }
  
   
  }
  
  /* Mobile screens */
  @media (max-width: 768px) {
    .client-heading {
      font-size: 2vh; /* Smaller for mobile screens */
    }
  
    .client-title {
      font-size: 5.62vh; /* Smaller for mobile screens */
    }
  
    /* Additional mobile-specific styles can go here if needed */
  }
  