/* .serv-but {
    font-size: 16px;
    margin: 19px 63px;
    padding: -11px 76px 29px 39px;
    border: 2px solid;
    display: inline-block;
    transition: all 0.3s linear;
} */

/* .services{
    margin-top: 60px;
} */

#services{
    margin-top: 100px;
}

.services-heading{
    font-size: 3vh;
    font-weight: 500;
    font-family: Alegreya;
    color: #ccc;
}

.services-title{
    word-wrap: break-word; 
    line-height: 1.1;
    font-size: 10vh;
    font-family: Alegreya;
    font-weight: 700;
}


/* Tablet screens */
@media (max-width: 1024px) {
    .services-heading {
      font-size: 2vh; /* Slightly smaller than desktop */
    }
  
    .services-title {
      font-size: 4.85vh; /* Slightly smaller than desktop */
    }
  
   
  }
  
  /* Mobile screens */
  @media (max-width: 768px) {
    .services-heading {
      font-size: 2vh; /* Smaller for mobile screens */
    }
  
    .services-title {
      font-size: 5.62vh; /* Smaller for mobile screens */
    }
  
    /* Additional mobile-specific styles can go here if needed */
  }
  