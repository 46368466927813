.content-division {
    margin-top: 90px;
    font-family: 'Alegreya', serif;
    letter-spacing: 0.1vh;
}

.alignment {
    text-align: justify;
}

.imageAlignment {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 500px) {
    .content-division {
        margin-top: 70px;
        font-family: 'Alegreya', serif;
        letter-spacing: 0.1vh;
        padding: 0px 25px;
    }

    .alignment {
        text-align: center;
    }
}