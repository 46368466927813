/* Large screens (desktop) */

h1,
.h1 {
  font-size: 4.5rem;
  font-weight: 800;
  width: 150%;
}

.ban-but {
  font-size: 16px;
  margin: -23px 300px 0;
  background-color: #f3ae9e;
  padding: 17px 31px;
  border: 2px solid;
  display: inline-block;
  transition: all 0.3s linear;
  border-color: #e8d95b;
  white-space: nowrap;
  width: auto;
}

h2,
.h2 {
  font-size: 25px;
  font-weight: 300;
  color: #111;
  padding: 14px 126px 4px 139px;
  width: 120%;
}

#banner-content {
  width: 38.2%;
}

.section-heading {
  font-family: 'Alegreya', serif;
  font-size: 3.5rem;
  font-weight: 800;
  color: #111;
  margin: 0;
  padding: 22px 0 20px;
  margin-left: 60px;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  float: left;
}

section-heading h1 {
  /* Adjust this value as needed */
}

section-heading h2 {
  margin-top: 30px;
  /* Adjust this value as needed */
}

h1 {
  padding-top: 5%;
  padding: 20px 140px;
  margin-top: -80px;
  margin-bottom: 60px;
}

.without-parallax-visibility {
  display: none;
}



/* Tablet screens */
@media (max-width: 1024px) {



  h1,
  .h1 {
    font-size: 6vh;
    font-weight: 800;
    width: 100%;
  }

  /* .ban-but {
    font-size: 14px;
    margin: -10px 0;
    padding: 10px 20px;
    width: 100%;
  } */

  .ban-but {
    font-size: 14px;
    margin-right: 35px;
    margin-left: 70px;
    /* margin: -10px 0; */
    /* margin-top: 10px; */
    padding: 14px 20px;
    padding-top: 14px;
    /* padding-left: -50px; */
    padding-right: 90px;
    width: 10%;
    background-color: #f3ae9e;
    border: 2px solid;
    display: inline-block;
    transition: all 0.3s linear;
    border-color: #e8d95b;
    white-space: nowrap;
  }

  h2,
  .h2 {
    font-size: 2vh;
    font-weight: 300;
    padding: 14px 0;
    width: 100%;
  }

  #banner-content {
    width: 60%;
  }

  .section-heading {
    font-size: 2rem;
    font-weight: 800;
    margin: 0;
    padding-top: 5px;
    padding-left: 50px;
    margin-left: 0;
  }

  section-heading h1 {
    /* Adjust this value as needed */
    
  }

  section-heading h2 {
    margin-top: 20px;
    /* Adjust this value as needed */
  }

  h1 {
    padding-top: 5%;
    padding: 10px 0;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .parallax-visibility{
    display: none
  }

  .without-parallax-visibility {
    display: block;
  }

}

/* Mobile screens */
@media (max-width: 700px) {

  h1,
  .h1 {
    font-size: 2rem;
    font-weight: 800;
    width: 100%;
  }

  .ban-but {
    font-size: 14px;
    margin: -10px 0;
    padding: 10px 20px;
    width: 100%;
  }

  h2,
  .h2 {
    font-size: 18px;
    font-weight: 300;
    padding: 14px 0;
    width: 100%;
  }

  #banner-content {
    width: 100%;
  }

  .section-heading {
    font-size: 1.5rem;
    font-weight: 800;
    margin: 0;
    padding: 10px 0;
    margin-left: 0;
  }

  section-heading h1 {
    /* Adjust this value as needed */
  }

  section-heading h2 {
    margin-top: 20px;
    /* Adjust this value as needed */
  }

  h1 {
    padding-top: 5%;
    padding: 10px 0;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .parallax-visibility {
    display: none
  }

  .without-parallax-visibility {
    display: block;
  }
}