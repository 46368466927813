
.nav-show {
    transform: translateY(0);
    transition: transform 0.1s ease-in-out;
    font-family: 'Alegreya', serif;
    font-weight: 400;
    font-size: larger;
  }
  
  .nav-hide {
    transform: translateY(-100%);
    transition: transform 0.1s ease-in-out;
  }
