.footer-distributed {
	background: #c35058;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	width: 100%;
	text-align: left;
	font: bold 16px;
	font-family: 'Alegreya', serif;
	padding: 55px 50px;
	color: black;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
	width: 40%;
}

.lks {
	padding-left: 5px;
}

.link-1 {
	padding-left: 0;
}

/* The company logo */

.footer-distributed h3 {
	color: white;
	font: 36px 'montserrat', cursive;

	margin: 0;
}

.footer-distributed h3 span {
	color: lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links {
	color: #000000;
	margin: 20px 0 12px;
	padding: 0;
}

.footer-distributed .footer-links a,
.addedLinks {
	display: inline-block;
	line-height: 1.8;
	font-weight: bold;
	font-family: sans-serif;
	text-decoration: none;
	color: white;
}

.fixAbout {
	/* margin: 0;
	padding: 0; */
	font-size: medium;
	font-weight: bold;
	font-family: sans-serif;
}

.aboutDescription {
	font-size: 1rem;
	font-weight:bold;
  font-family: sans-serif;
}

.footer-distributed .footer-company-name {
	color: white;
	font-size: 14px;
	font-weight: bold;
	font-family: sans-serif;
	margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
	margin-top: 25px;
	width: 25%;
	text-align: center;
}

.contactDetails {
	margin-bottom: 15px;
}

.address {
	padding-left: 10px;
}

.footer-distributed .footer-center i {
	background-color: #33383b;
	color: white;
	font-size: 25px;
	width: 38px;
	height: 38px;
	border-radius: 50%;
	text-align: center;
	line-height: 42px;
	margin: 10px 15px;
	vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
	font-size: 17px;
	line-height: 38px;
}

.footer-distributed .footer-center p {
	display: inline-block;
	color: white;
	font-weight: bold;
	font-family: sans-serif;
	vertical-align: middle;
	margin: 0;
}

.footer-distributed .footer-center p span {
	display: block;
	font-weight: normal;
	font-size: 14px;
	line-height: 2;
}

.footer-distributed .footer-center p a {
	color: rgb(26, 53, 229);
	text-decoration: none;
	color: white;
}

.footer-distributed .footer-links a:before {
	content: "|";
	font-weight: 300;
	font-size: 20px;
	left: 0;
	color: white;
	display: inline-block;
	padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
	content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
	width: 30%;
	text-align: right;
	margin-top: 25px;
}

.footer-distributed .footer-company-about {
	line-height: 20px;
	color: white;
	font-size: 13px;
	font-weight: normal;
	margin: 0;
}

.footer-distributed .footer-company-about span {
	display: block;
	color: white;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 20px;
}

.footer-distributed .footer-icons {
	margin-top: 25px;
}

.footer-distributed .footer-icons a {
	display: inline-block;
	width: 28px;
	height: 30px;
	cursor: pointer;
	/* background-color: #000000; */
	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-left: 10px;
	margin-bottom: 5px;
}


@media (max-width: 880px) {

	.footer-distributed {
		font: bold 14px sans-serif;
	}

	.contactDetails {
		margin-bottom: 5px;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right {
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i {
		margin-left: 0;
	}

}