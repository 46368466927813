/* Apply the following styles when the screen width is at least 1200px */



#about {
    -webkit-font-smoothing: antialiased;
    font-family: 'Alegreya', serif;
    font-size: 20px;
    font-weight: normal;
    color: #111;
    direction: ltr;
    box-sizing: border-box;
    clear: both;
    overflow: hidden;
}
/* 
[class*='col-'] {
    float: left;
    padding: 20px;
} */

/* .col-2-3 {
    width: 66.66%;
} */

.col-md-9 {
    margin-top: 30px;
    /* Adjust the top margin as needed */
    margin-bottom: 30px;
    /* Adjust the bottom margin as needed */
    margin-right: 0px;
}

/* Add margin to the top of the Introduction intro */
.introduction {
    margin-top: 10px;
}



.intro-heading h2:after {
    background: #d2b356;
    content: "";
    display: block;
    width: 30px;
    height: 5px;
    margin-top: 30px;

}

div {
    display: block;
}

.intro-heading {
    margin: 0;
    padding: 0 0 10px 0;
    box-sizing: border-box;
    font-size: 62px;
    font-family: 'Alegreya', serif;
    font-weight: bolder;
}

.intro-title {
    font-family: 'Alegreya', serif;
    font-size: 35px;
    font-weight: 700;
    color: #111;
    padding: 0px;
    box-sizing: border-box;
}

.intro-subtitle {
    font-family: 'Alegreya', serif;
    font-size: 18px;
    padding-top: 0;
    font-weight: 300;
    color: #9c9c9c;
}

.fadeInTop {
    opacity: 0;
    animation: fadeIn 2s;
    transform: translateY(20px);
    transition:  transform 2s ease;
  }

  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  
  .fadeInTop:nth-child(2) {
    transition-delay: 0.5s;
    animation-delay: 0.5s;
  }

  .fadeInTop:nth-child(3) {
    transition-delay: 1s;
    animation-delay: 1s;
  }

  .fadeInTop:nth-child(4) {
    transition-delay: 1.5s;
    animation-delay: 1.5s;
  }
    

/* .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
} */

h4 {
    /* margin: 0;
    padding: 0; */
    /* direction: ltr; */
}


.icon-block {
    /* Your styles for the icon blocks */
    /* position: relative; */
    /* font-family: 'Alegreya', serif; */
}

.icon-top {
    /* display: block; */
    padding-top: 50px;
    padding-left: 120px;
}

.col-2 {
    width: 50%;
}

.icon {
    /* Your styles for the icons */
}

.icon-block-description {
    /* Your styles for the icon block descriptions */
    /* display: block; */
    /* font-family: 'Alegreya', serif; */
    /* margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px; */

}



@media (max-width: 1024px) {
    /* Adjust font sizes and paddings for tablet screens */

    .intro-heading {
      font-size: 20px; /* Smaller than desktop */
    }
  
    .intro-title {
      font-size: 30px; /* Smaller than desktop */
    }
  
    .intro-subtitle {
      font-size: 16px; /* Smaller than desktop */
    }
  
    .icon-top {
      padding-top: 30px; /* Less padding than desktop */
      padding-left: 60px; /* Less padding than desktop */
    }
  
    /* Adjust column width for tablet layout */
    .col-2 {
      width: 100%; /* Full width for smaller screens */
    }
  
    /* You may also need to adjust margins and paddings of other elements */
  }
  


  @media (max-width: 768px) {
    /* Adjust font sizes and paddings for mobile screens */
    .intro-heading {
      font-size: 30px; /* Smaller than tablet */
    }
  
    .intro-title {
      font-size: 30px; /* Smaller than tablet */
    }
  
    .intro-subtitle {
      font-size: 14px; /* Smaller than tablet */
    }
  
    .icon-top {
      padding-top: 20px; /* Less padding than tablet */
      padding-left: 30px; /* Less padding than tablet */
    }
  
    /* Adjust column width for mobile layout */
    .col-2 {
      width: 100%; /* Full width for smaller screens */
    }
  
    /* Stack the icon blocks vertically on small screens */
    .icon-block {
      width: 100%;
      display: block; /* Stack the blocks instead of side by side */
    }
  
    /* Adjust the icon size for mobile */
    .icon {
      font-size: 1.5rem; /* Smaller icons on mobile */
    }
  
    /* Ensure the container has some padding on the sides */
    .about-container, .introduction {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  