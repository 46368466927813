/* gallery.css */
/* Style for the gallery images */
.gallery-image {
    max-width: 100%;
    height: auto;
    opacity: 1;
    transition: opacity 0.5s;
    margin: 5px;
    padding-right: 10px;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Adjust the opacity (0.7) as needed */
    z-index: 999; /* Ensure it's above other elements */
  }

  /* Animation for fadeIn */
  .wow.fadeIn {
    animation-name: fadeIn;
  }

  .gallery{
    margin-top: 60px;
    margin-bottom: 60px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  