/* Large screens (desktop) */
.about-container {
    font-family: 'Alegreya', serif;
    margin-top: 20vh;
    padding: 0 25vh;
}

.aboutHeading {
    font-size: 4rem;
    margin-top: 20px;
    font-weight: 700;
    line-height: 4vh;
    margin-bottom: 6vh;
}

.heading {
    font-size: 6vh;
    margin-top: 8vh;
    font-weight: 600;
    margin-bottom: 4vh;
}

.list-styling {
    text-align: justify;
    margin-left: 36vh;
    margin-bottom: 15vh;
    margin-top: 5vh;
    font-size: 3vh;
    line-height: 5vh;
}

.content {
    font-size: 3vh;
    line-height: 5vh;
}

/* Tablet screens */
@media (max-width: 920px) {
    .about-container {
        margin-top: 10vh;
        padding: 2vh;
    }

    .aboutHeading {
        font-size: 3rem;
        margin-top: 10px;
        line-height: 3vh;
        margin-bottom: 4vh;
    }

    .heading {
        font-size: 4vh;
        margin-top: 6vh;
        margin-bottom: 3vh;
    }

    .list-styling {
        margin-left: 10vh;
        margin-bottom: 8vh;
        margin-top: 2vh;
        font-size: 2vh;
        line-height: 3vh;
    }

    .content {
        font-size: 2vh;
        line-height: 3vh;
    }
}

/* Mobile screens */
@media (max-width: 480px) {
    .about-container {
        margin-top: 5vh;
        padding: 7.5vh;
    }

    .aboutHeading {
        font-size: 2.5rem;
        margin-top: 5px;
        line-height: 2.5vh;
        margin-bottom: 3vh;
    }

    .heading {
        font-size: 3.5vh;
        margin-top: 4vh;
        margin-bottom: 2vh;
    }

    .list-styling {
        margin-left: 5vh;
        margin-bottom: 4vh;
        margin-top: 1vh;
        font-size: 1.5vh;
        line-height: 2vh;
    }

    .content {
        font-size: 1.5vh;
        line-height: 2vh;
    }
}
